<template>
<!--  -->
  <div :class="$store.state.tyPhone == 'ios'?'contentItems iosList':'contentItems'">
    <div class="contentTittle" v-if="fromType == 1">{{tittle}}</div>
    <div class="time" v-if="fromType == 1">{{time}}</div>
    <!-- 文本 -->
    <div class="text" v-html="aa" v-if="fromType == 1"></div>
    <div class="attachment" v-if="fromType == 1 && fileList.length>0" >
          附件下载：
        <div class="filedName" v-for="(item,index) in fileList" :key="index" @click="download(item)">
            <a>{{item.name}}</a>
        </div>
    </div>
    <!-- pdf -->
    <pdf v-else-if="fromType == 2" :nkpdfUrl="nkpdfUrl"></pdf>
    <!-- 嵌入外联 -->
    <div class="iframeUrl" v-else-if="fromType == 3">
    <iframe  :src="src" frameborder="0" ref="refed" width="100%" height="100%" ></iframe>
    </div>
    <!-- 表单 -->
    <fromlist v-else-if="fromType == 4" :fromlist="fromlist" :fromTittle="fromTittle"></fromlist>
    <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>

<script>
import { getContentDetail,getServiceContentDetail,downloadFile} from '@/api/home.js';
import fromlist from '@/components/contentlist/fromlist.vue'
import pdf from '@/components/pdf/pdf.vue'
import  axios from 'axios'
export default {
    name:"contentItems",
    components:{
        fromlist,
        pdf
    },
    data(){
        return{
            aa:'',
            src:'',
            nkpdfUrl:'',
            fromlist:null,
            fromType:1,
            contentList1:[],
            tittle:'',
            time:'',
            fromTittle:'',
            fileList:[]
        }
    },
    computed:{
        menuType(){
            return this.$store.state.menuType
        }
    },
    mounted(){
        this.init()
        
    },
    methods:{
        // 下载
        async download(item){
            this.$toast.loading({
              message: '加载中',
              duration: 0, // 会一直存在,如果有下一个this.$toast被调用，它就会被覆盖，也就消失了
              mask: true
            });
            let link = document.createElement('a')
            link.style.display = 'none'
            let url = item.url//绝对地址
            link.href = url
            link.download = item.name//文件名
            document.head.appendChild(link)
            link.click()
            document.head.removeChild(link)
            this.$toast.clear();           
        },
        async init(){
            if(this.menuType == 'lanmu'){
                let data = await getContentDetail(this.$route.query.id)
                let datacontent = data.data.data
                if(datacontent.detailType == 1){
                    if(datacontent.type == 1){
                    this.fromType = 1
                    this.tittle = datacontent.title
                    if(this.$store.state.jxbApp){
                        window.location.href=`ssptitle://${this.tittle}`
                    }else{
                        document.title = this.tittle
                    }
                    
                    this.time = datacontent.publishTime
                    let a = datacontent.content.replace(/<img/g, '<img style="max-width: 100%;"')
                    // this.aa = a.replace(/<p/g, '<p style="font-size:16px;"')
                    this.aa = '<div style="font-size:16px;">'+a+'</div>'
                    if(datacontent.fileId || datacontent.fileName){
                        let fileUrl = datacontent.fileId.split(',')
                        let fileName = datacontent.fileName.split(',')
                        for(let i in fileUrl){
                            let obj = {
                                url:fileUrl[i],
                                name:fileName[i]
                            }
                            this.fileList.push(obj)
                        }
                    }
                    }else if(datacontent.type == 2){
                        this.fromType = 2
                        this.nkpdfUrl = datacontent.pdfFileId
                    }else{
                        this.fromType = 3
                        this.src = datacontent.outUrl
                    }
                }else{
                    this.fromType = 4
                    this.fromTittle = datacontent.title
                    this.fromlist = datacontent.formInfo
                }
            }else{
                let data = await getServiceContentDetail(this.$route.query.id)
                let datacontent = data.data.data
                if(datacontent.detailType == 1){
                    if(datacontent.type == 1){
                    this.fromType = 1
                    this.tittle = datacontent.title
                    if(this.$store.state.jxbApp){
                        window.location.href=`ssptitle://${this.tittle}`
                    }else{
                        document.title = this.tittle
                    }
                    this.time = datacontent.publishTime
                    let a = datacontent.content.replace(/<img/g, '<img style="max-width: 100%;"')
                    // this.aa = a.replace(/<p/g, '<p style="font-size:16px;"')
                    this.aa = '<div style="font-size:16px;">'+a+'</div>'
                    if(datacontent.fileId || datacontent.fileName){
                    let fileUrl = datacontent.fileId.split(',')
                    let fileName = datacontent.fileName.split(',')
                    for(let i in fileUrl){
                        let obj = {
                            url:fileUrl[i],
                            name:fileName[i]
                        }
                        this.fileList.push(obj)
                    }
                    }
                    }else if(datacontent.type == 2){
                    this.fromType = 2
                    this.nkpdfUrl = datacontent.pdfFileId
                    }else{
                    this.fromType = 3
                    this.src = datacontent.outUrl
                    }
                }else{
                    this.fromType = 4
                    this.fromTittle = datacontent.title
                    this.fromlist = datacontent.formInfo
                }
            }
            
        }
    }
}
</script>

<style lang="less" scoped>
.iframeUrl{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.text{
    padding: 0 .542rem;
    box-sizing: border-box;
    margin-top: 1rem;
}
.attachment{
    font-size: 0.433rem;
    padding: 0 0.542rem;

    .filedName{
        color: #0000d9;
        margin: .178rem 0;
        cursor: pointer;
    }
    a{
        display: block;
    }
}
.contentTittle{
    margin-top: .622rem;
    font-size: .533rem;
    text-align: left;
    color: #333333;
    font-weight: 600;
    margin-left: .542rem;
}
.time{   
    margin-top: .267rem;
    color: #838282;
    font-size: .356rem;
    margin-left: .542rem;
}
.iosList{
  padding-bottom: 1.778rem;
}
</style>